<template>
  <div>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-title>
            <v-row>
              <v-col> Mudanças de Preços Recentes </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle>
            <v-row class="d-flex align-center">
              <v-col>
                <v-btn :disabled="!selected.length" @click="printTags()" color="primary">
                  <v-icon left>mdi-printer</v-icon>
                  Etiquetas
                </v-btn>
              </v-col>
              <v-col>
                <app-text-field v-model="searchParams.cost_update_at_start" label="Data Inicial" type="date" outlined
                  dense @input="select()" />
              </v-col>

              <v-col>
                <app-text-field v-model="searchParams.cost_update_at_end" label="Data Final" type="date" outlined dense
                  @input="select()" />
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text>
            <v-data-table :headers="headers" v-model="selected" :items="products.data" :items-per-page="-1"
              ref="productTable" :mobile-breakpoint="0" show-select disable-sort hide-default-footer dense>
              <template v-slot:[`item.price_cash`]="{ item }">
                {{ $format.decimal(item.product_variants[0].price_cash) }}
              </template>
              <template v-slot:[`item.price_forward`]="{ item }">
                {{ $format.decimal(item.product_variants[0].price_forward) }}
              </template>
              <template v-slot:[`item.cost_updated_at`]="{ item }">
                {{ $format.dateTimeBr(item.cost_updated_at) }}
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions class="justify-center">
            <app-pagination @click="select($event)" :data="products" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Código", value: "code", width: "5%", align: "center" },
        { text: "Produto", value: "name" },
        { text: "Preço AV", value: "price_cash", align: "center" },
        { text: "Preço PZ", value: "price_forward", align: "center" },
        {
          text: "Data",
          value: "cost_updated_at",
          align: "center",
          width: "20%",
        },
      ],
      products: {
        data: [],
      },
      selected: [],
      searchParams: {
        cost_update_at_start: new Date(
          new Date().setDate(new Date().getDate() - 1)
        )
          .toISOString()
          .slice(0, 10),

        cost_update_at_end: new Date().toISOString().slice(0, 10),
        orderBy: "cost_updated_at",
        orderByDirection: "desc",
        status: 1
      },
    };
  },

  created() {
    this.select();
  },

  methods: {
    select(page) {
      this.$loading.start();
      this.searchParams.page = page;
      this.$http
        .index("product/product", this.searchParams)
        .then((response) => {
          this.products = response.products;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    printTags() {
      this.$store.commit("productsPrintTag/resetProducts");

      this.selected.forEach((product) => {
        this.$store.commit("productsPrintTag/increaseProduct", {
          product: product,
          quantity: 1,
        });
      });

      window.open("/produtos/etiquetas/imprimir");
    },
  },
};
</script>

<style></style>